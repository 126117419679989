.footer{
    width: 100%;
    padding: 3rem 0;
    background-color: black;

}

.footer .footer-container h4{
    color:white;
}

.footer .footer-container .left p1{
    color:white;
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom:0.8rem;
}

.footer-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
}

.left{
    height:100%;
    max-width:100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.location {
    display: flex;
    margin-bottom:0.8rem;
}

.location p{
    line-height: 30px;
}

.right{
    height: 100%;
    max-width:100%;
    display: flex;
    flex-direction:column;
    padding:1rem;
}

.email{
    display: flex;
    margin-top: 5.7rem;
    margin-bottom:0.8rem;
}


@media screen and (max-width: 640px) {
    .footer-container{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0px;
    }
    .right{
        height: 100%;
        max-width:100%;
        display: flex;
        flex-direction:column;
        padding: 1rem;
    }
}
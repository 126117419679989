.about{
    width: 100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.interests{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    background-color: rgb(221, 252, 252);
}

.about .left{
    text-align: center;
    margin: auto;
    padding: 1rem;
    max-width: 550px;

}
.interests .right{
    text-align: left;
    margin: auto;
    padding: 1rem;
    padding-bottom: 1rem;
    max-width: 350px;

}

.interests .right h1{
    padding-bottom: 1rem;
}

.interests .right p{
    padding-bottom: 1rem;
}

.interests .left{
    max-width: 700px;

}
.interests .left .img-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    align-items: center;
    text-align:center;
}

.interests .left .img{
    max-width: 100%;
    margin-left: 55%;
}

.about .left p {
    margin: 0.5rem;
    font-size: large;
}

.about .right{
    max-width: 700px;
}

.about .right .img-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    align-items: center;
    text-align:center;
}

.about .right .img{
    max-width: 90%;
}
@media screen and (max-width: 1040px) {
    .about .right .img{
        max-width: 90%;
        align-self: center;
        margin-left: 55%;
    }
    .interests .right h1{
        padding-bottom: 1rem;
        text-align: center;
    }
    .interests .right p{
        text-align: left;
        padding-left: 4rem;
        padding-right: 2rem;
        padding-bottom: 1rem;
    }
    .interests .left .img{
        max-width: 90%;
        align-self: center;
        margin-left: 55%;
    }
}
.project-heading{
    text-align: center;
    padding: 3rem 0 2rem 0;
}

.project-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}

.project-card{
    background: rgb(184, 222, 222);
    padding: 1.2rem 1.2rem;
    
}

.project-card img{
    width: 100%;
  
}

.project-title {
    padding: 1rem 0;
}
.pro-details p{
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
}

.pro-btns{
    display: flex;
    padding: 0.5rem 0rem;

}

.pro-btns .btn {
    padding: 0.5rem 1rem;
}

@media screen and (max-width: 1040px) {
    .project-container{
        max-width:90%;
        margin:auto;
        grid-template-columns: 1fr;
    }
    .project-card{
        background: rgb(184, 222, 222);
        max-width: 100%;
        padding: 1.2rem 1.2rem;
        
    }
    .project-heading{
        text-align: center;
        padding: 2rem 0 1.5rem 0;
        margin-left: 20px;
        margin-right: 20px;
    }
}

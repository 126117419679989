.hi{
    color:white;
    font-size:2rem;
    
}

.mask{
    width:100%;
    height: 100vh;
    position: relative;
}

.intro-img{
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.logo {
    width: 20%;
    height: 20%;
    
}

.mask::after{
    content: "";
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(32, 32, 32);
    opacity: 0.5;

}

.hero{
    height: 100vh;
    width: 100%;
}

.hero .content{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.hero .content h1{
    color: white;
    font-size: 4rem;
    padding:0.6rem 0 1.5rem;
}

.hero .content p{
    color: white;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 400;

}

.content .btn {
    margin: 1rem 1rem;
}

@media screen and (max-width: 640px){
    .hero .content h1{
        color: white;
        font-size: 3rem;
        padding:0.6rem 0 1.5rem;
    }
    
    .hero .content p{
        color: white;
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 400;
    
    }

}
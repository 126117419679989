 .hero-img{
    width:100%;
    background: black;
    height: 60vh;
    position:relative;

}

.hero-img h1 {
    color: white;
    text-transform:uppercase;
    font-size:3rem;
}

.hero-img p{
    color: white;
    font-size:2rem;
}

.mask2{
    width:100%;
    height: 60vh;
    position: relative;
}

.mask2::after{
    content: "";
    width: 100%;
    height: 60vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(32, 32, 32);
    opacity: 0.5;

}

.backImg{
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-position: center top;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

}

.heading{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}


@media screen and (max-width: 640px) {
    .hero-img h1 {
        color: white;
        text-transform:uppercase;
        font-size:2rem;
    }

    .hero-img p{
        color: white;
        font-size:1.5rem;
    }
}